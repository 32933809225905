import { updateB2bCart } from '@/redux/actions';
import store from '../redux/store';

export function handleB2bCart(
  clickedItem,
  activeVariant,
  type,
  minimumOrderQuantity,
  quantityEnteredByUser
) {
  clickedItem = {
    ...clickedItem,
    item_name: clickedItem.name,
    actual_price: 0,
    item_price: 0,
    amount: 0,
    actual_amount: 0,
    discounted_price: 0,
    item_id: clickedItem.id || clickedItem.item_id,
    buyer_pay_value: 0,
    item_type: 'catalog',
  };

  const b2bCartItems = store.getState().cartReducer.items;
  if (activeVariant) {
    if (type === 'ADD') {
      const itemAlreadyExistsInCartIndex = b2bCartItems.findIndex(
        (item) => item.item_id === clickedItem.item_id
      );
      if (itemAlreadyExistsInCartIndex !== -1) {
        const doesVariantAlreadyExistsInCartIndex = b2bCartItems[
          itemAlreadyExistsInCartIndex
        ].variants_selected.findIndex(
          (variant) => variant.variant_id === activeVariant.variant_id
        );
        if (doesVariantAlreadyExistsInCartIndex !== -1) {
          b2bCartItems[itemAlreadyExistsInCartIndex].variants_selected[
            doesVariantAlreadyExistsInCartIndex
          ].quantity =
            quantityEnteredByUser ??
            b2bCartItems[itemAlreadyExistsInCartIndex].variants_selected[
              doesVariantAlreadyExistsInCartIndex
            ].quantity;
          let itemQuantityWithoutCurrentVariant = 0;
          b2bCartItems[itemAlreadyExistsInCartIndex].variants_selected?.forEach(
            (variant) => {
              if (
                variant.variant_id !==
                b2bCartItems[itemAlreadyExistsInCartIndex].variants_selected[
                  doesVariantAlreadyExistsInCartIndex
                ].variant_id
              ) {
                itemQuantityWithoutCurrentVariant =
                  itemQuantityWithoutCurrentVariant + variant.quantity;
              }
            }
          );
          b2bCartItems[itemAlreadyExistsInCartIndex].quantity =
            itemQuantityWithoutCurrentVariant +
            b2bCartItems[itemAlreadyExistsInCartIndex].variants_selected[
              doesVariantAlreadyExistsInCartIndex
            ].quantity;
        } else {
          activeVariant.quantity = minimumOrderQuantity || 1;
          b2bCartItems[itemAlreadyExistsInCartIndex].variants_selected.push(
            activeVariant
          );
          let itemQuantityWithoutCurrentVariant = 0;
          b2bCartItems[itemAlreadyExistsInCartIndex].variants_selected?.forEach(
            (variant) => {
              if (variant.variant_id !== activeVariant.variant_id) {
                itemQuantityWithoutCurrentVariant =
                  itemQuantityWithoutCurrentVariant + variant.quantity;
              }
            }
          );
          b2bCartItems[itemAlreadyExistsInCartIndex].quantity =
            itemQuantityWithoutCurrentVariant + activeVariant.quantity;
        }
      } else {
        activeVariant.quantity = minimumOrderQuantity || 1;
        clickedItem.variants_selected = [activeVariant];
        clickedItem.quantity = minimumOrderQuantity || 1;
        b2bCartItems.push(clickedItem);
      }
      store.dispatch(updateB2bCart(b2bCartItems));
    } else {
      const itemAlreadyExistsInCartIndex = b2bCartItems.findIndex(
        (item) => item.item_id === clickedItem.item_id
      );
      const filteredVariantItems = b2bCartItems[
        itemAlreadyExistsInCartIndex
      ].variants_selected.filter(
        (variant) => variant.variant_id !== activeVariant.variant_id
      );
      if (filteredVariantItems.length) {
        b2bCartItems[itemAlreadyExistsInCartIndex].variants_selected =
          filteredVariantItems;
        store.dispatch(updateB2bCart(b2bCartItems));
      } else {
        const filteredB2bCartItems = b2bCartItems.filter(
          (item) => item.item_id !== clickedItem.item_id
        );
        store.dispatch(updateB2bCart(filteredB2bCartItems));
      }
    }
  } else {
    if (type === 'ADD') {
      const itemAlreadyExistsInCart = b2bCartItems.find(
        (item) => item.item_id === clickedItem.item_id
      );
      if (itemAlreadyExistsInCart) {
        b2bCartItems.forEach((item) => {
          if (item.item_id === itemAlreadyExistsInCart.item_id) {
            item.quantity = quantityEnteredByUser ?? item.quantity;
          }
        });
      } else {
        clickedItem.quantity = minimumOrderQuantity || 1;
        b2bCartItems.push(clickedItem);
      }
      store.dispatch(updateB2bCart(b2bCartItems));
    } else {
      const filteredB2bCartItems = b2bCartItems.filter(
        (item) => item.item_id !== clickedItem.item_id
      );
      store.dispatch(updateB2bCart(filteredB2bCartItems));
    }
  }
}
