import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#999999',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#E4E4E4',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E4E4E4',
    },
    '&:hover fieldset': {
      borderColor: '#E4E4E4',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#E4E4E4',
    },
  },
  '& .MuiOutlinedInput-input': {
    height: 48,
  },
});
