import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { styled } from '@mui/material/styles';

export const StyledDateTimePicker = styled(MobileDateTimePicker)({
  '& label': {
    fontFamily: 'Montserrat',
  },
  '& label.Mui-focused': {
    color: '#999999',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#E4E4E4',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E4E4E4',
    },
    '&:hover fieldset': {
      borderColor: '#E4E4E4',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#E4E4E4',
    },
  },
  '& .MuiOutlinedInput-input': {
    height: 48,
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: 14,
    fontStyle: 'normal',
  },
});
